<template>
  <div class="jukeCard">
    <Toolbar :toolbar="toolbar" :data="record" />
    <Svgs45 :primaryColor="primaryColor" :secondaryColor="secondaryColor" :tertiaryColor="tertiaryColor"
      :design="design" />
    <div class="innerCard">
      <div class="line song sideA">{{ sideA }}</div>
      <div class="line artist">{{ artist }}</div>
      <div class="line song sideB">{{ sideB }}</div>
    </div>
  </div>
</template>

<script>
import Svgs45 from "@/components/45/Svgs45";
import Toolbar from "../Toolbar.vue";
export default {
  name: "card45",
  props: {
    customize: {
      type: Object,
    },
    artist: String,
    sideA: String,
    sideB: String,
    record: Object,
    toolbar: Array,
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    primaryColor() {
      if (this.customize && this.customize.primaryColor) return this.customize.primaryColor;
      return this.user.meta.defaultTitleStrip.primaryColor;
    },
    secondaryColor() {
      if (this.customize && this.customize.secondaryColor) return this.customize.secondaryColor;
      return this.user.meta.defaultTitleStrip.secondaryColor;
    },
    tertiaryColor() {
      if (this.customize && this.customize.tertiaryColor) return this.customize.tertiaryColor;
      return this.user.meta.defaultTitleStrip.tertiaryColor;
    },
    design() {
      if (this.customize && this.customize.design) return this.customize.design;
      return this.user.meta.defaultTitleStrip.design;
    },
  },
  components: {
    Svgs45,
    Toolbar
  }
};
</script>

<style scoped>
.line {
  position: absolute;
  overflow: hidden;
  text-align: center;
  left: 0px;
  width: 3in;
  height: 23px;
}

.song {
  font-weight: 900;
  font-size: 17px;
}

.artist {
  position: absolute;
  overflow: hidden;
  text-align: center;
  top: 38px;
  left: 0px;
  width: 3in;
  font-size: 14px;
  font-weight: 700;
}

.sideA {
  top: 9px;
}

.sideB {
  top: 62px;
}

.jukeCard .toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  opacity: 0;
  transition: opacity .3s;
}

.jukeCard:hover .toolbar {
  display: block;
  opacity: 0.3;
}

.jukeCard:hover .toolbar:hover {
  opacity: 1;
}

.jukeCard {
  text-transform: uppercase;
  position: relative;
  text-align: center;
  font-family: "Raleway", sans-serif;
  width: 3in;
  height: 1in;
  background-repeat: no-repeat;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari, Edge */
  color-adjust: exact !important;
}
</style>
