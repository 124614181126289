<template>
  <b-container>
    <h2>Profile</h2>
    <b-form @submit="submit">
      <b-row>
        <b-col cols="6">
          <b-form-group id="discogsUsername-group" label="Discogs Username:" label-for="discogsUsername">
            <b-form-input id="discogsUsername" v-model="form.discogsUsername" />
          </b-form-group>
          <b-link id="popover-target-1" :style="{ float: 'right', marginLeft: '40px' }">
            What is this?
          </b-link>
          <b-popover target="popover-target-1" triggers="hover" placement="right">
            <template #title>How to find a Discogs Token</template>
            <ol>
              <li>
                Go to
                <b-link href="https://www.discogs.com/settings/developers" target="_blank">
                  Discogs Developers
                </b-link>
                .
              </li>
              <li>Click Generate new Token</li>
              <li>
                Copy the Current Token.
              </li>
              <li>
                Paste it into the 'Discogs Token:' field
              </li>
            </ol>
            <div><img src="/images/discogs_token.png" alt="Locating Discogs ID" :style="{
              border: '1px solid #ccc',
              width: '90%',
            }" /></div>
          </b-popover>
          <b-form-group id="discogsToken-group" label="Discogs Token:" label-for="discogsToken">


            <b-form-input id="discogsToken" v-model="form.discogsToken" />
          </b-form-group>
          <div class="d-flex">
            <b-button class="ml-auto" type="submit" variant="primary">Save</b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <h3>Default Title Strip Style</h3>
          <Card artist="Artist" sideA="Side A" sideB="Side B" />
          <br />
          <StripDesigner @customize="onUpdateCustomize" />
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import Card from "@/components/45/Card";
import { updateProfile } from "@/requests";
import StripDesigner from "@/components/inputs/stripDesigner";

export default {
  name: "ProfileComponent",
  data() {
    return {
      form: {
        discogsUsername: '',
        discogsToken: '',
      },
    };
  },
  mounted() {
    const user = this.$store.getters.getUser.meta
    this.form.discogsUsername = user.discogsUsername || ''
    this.form.discogsToken = user.discogsToken || ''
  },
  methods: {
    onUpdateCustomize(customize) {
      this.$store.dispatch('updateDefaultTitleStrip', customize)
    },
    submit(e) {
      e.preventDefault()
      updateProfile({
        discogsUsername: this.form.discogsUsername,
        discogsToken: this.form.discogsToken,
      }).then(() => {
        this.$bvToast.toast(`Profile Updated`, {
          title: 'Profile Updated',
          variant: 'success',
          toaster: 'b-toaster-top-right',
          solid: true
        })
      }
      )
    },
  },
  components: {
    StripDesigner,
    Card,
  },
};
</script>
