<template>
  <div class="m-4">
    <Profile />
  </div>
</template>

<script>
import Profile from "@/components/Profile";

export default {
  name: "profile",

  components: {
    Profile,
  },
};
</script>
