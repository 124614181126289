<template>
  <div v-html="dynamicSVG" />
</template>
  
<script>
import svgData from './svgData.json'; // Import the JSON data
export default {
  name: 'Svgs45',
  props: {
    primaryColor: {
      type: String,
      default: 'black',
    },
    secondaryColor: {
      type: String,
      default: 'black',
    },
    tertiaryColor: {
      type: String,
      default: 'black',
    },
    design: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    dynamicSVG() {
      let design = svgData.find((item) => item.id === this.design);
      if (!design) {
        design = svgData[1];
      }
      return design.svg
        .replace(/\${primaryColor}/g, this.primaryColor)
        .replace(/\${secondaryColor}/g, this.secondaryColor)
        .replace(/\${tertiaryColor}/g, this.tertiaryColor)

    },
  },
};
</script>
  