<template>
  <div class="toolbar">
    <b-button-toolbar class="toolbar" aria-label="Toolbar for card actions" v-if="toolbar && data">
      <b-button-group size="sm" class="mr-1" v-for="(item, stripIindex) in toolbar" :key="stripIindex">
        <b-button variant="warning" size="sm" @click="item.onClick(data)">
          <component :is="iconComponent(item.icon)" />
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>

<script>
import { BIconTrash, BIconPen } from "bootstrap-vue";

export default {
  name: 'Toolbar',
  props: {
    toolbar: {
      type: Array,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  methods: {
    iconComponent(iconName) {
      switch (iconName) {
        case "trash":
          return BIconTrash
        case "edit":
          return BIconPen
        default:
          return null
      }
    },
  },
}
</script>
